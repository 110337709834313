import SearchBoxComponent from '@wix/thunderbolt-elements/src/components/SearchBox/viewer/SearchBox';
import SearchBoxController from '@wix/thunderbolt-elements/src/components/SearchBox/viewer/SearchBox.controller';


const SearchBox = {
  component: SearchBoxComponent,
  controller: SearchBoxController
};


export const components = {
  ['SearchBox']: SearchBox
};


// temporary export
export const version = "1.0.0"
