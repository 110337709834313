import * as React from 'react';
import classNames from 'classnames';

import * as translations from '../../../i18n.constants';
import { SuggestionGroupProps } from '../../Suggestion.types';

import style from './Group.scss';

export const Group: React.FC<SuggestionGroupProps> = ({
  action,
  item,
  translate,
  useNewSuggestionsStyle,
}) => {
  const groupLinkText = translate(
    translations.NAMESPACE,
    translations.SUGGESTION_GROUP_SHOW_ALL_KEY,
    translations.SUGGESTION_GROUP_SHOW_ALL_DEFAULT,
  );

  const handleGroupClick: React.MouseEventHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    action();
  };

  return (
    <div
      className={classNames(style.root, {
        [style.newStyle]: useNewSuggestionsStyle,
      })}
    >
      <div className={style.inner}>
        <span>{item.title}</span>
        {!useNewSuggestionsStyle && item.url && (
          <a
            className={style.showAllLink}
            href={item.url}
            onClick={handleGroupClick}
            title={`${groupLinkText} ${item.title}`}
          >
            {groupLinkText}
          </a>
        )}
      </div>
      {!useNewSuggestionsStyle && <div className={style.divider} />}
    </div>
  );
};
