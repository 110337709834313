import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import classNames from 'classnames';

import {
  ISearchBoxProps,
  ISearchBoxImperativeActions,
} from '../SearchBox.types';
import { ITextInputImperativeActions } from '../../TextInput/TextInput.types';
import { keyCodes } from '../../../core/commons/a11y';
import { Suggestions } from './suggestionsComponent/Suggestions';
import * as translations from './i18n.constants';
import { useSuggestionNavigation } from './suggestionsComponent/useSuggestionNavigation';
import { useSuggestionsVisibility } from './suggestionsComponent/useSuggestionsVisibility';
import { ModalSuggestions } from './suggestionsComponent/ModalSuggestions';
import { SearchBoxForm } from './searchBoxForm';

import style from './style/SearchBox.scss';

const SearchBox: React.ForwardRefRenderFunction<
  ISearchBoxImperativeActions,
  ISearchBoxProps
> = (props, ref) => {
  const {
    autocompleteEnabled,
    changeValue,
    clearSuggestions,
    closeParentContainer = false,
    closeSuggestions,
    direction,
    id,
    isDisabled = false,
    isSuggestionsOpen = false,
    onAutocomplete,
    onBlur,
    onChange,
    onClear,
    onClick,
    onDblClick,
    onFocus,
    onKeyPress,
    onModalSuggestionsClose,
    onModalSuggestionsOpen,
    onMouseEnter,
    onMouseLeave,
    onSubmit,
    onSuggestionsFooterClick,
    placeholder = '',
    suggestions,
    suggestionsEnabled = false,
    suggestionsLoading = false,
    translate,
    useModalSuggestions,
    useNewSuggestionsStyle,
    defaultFormAction,
    value = '',
  } = props;

  const autocompleteValue =
    (autocompleteEnabled && props.autocompleteValue) || '';

  const [isInputFocused, setIsInputFocused] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    };
  });

  const inputRef = useRef<ITextInputImperativeActions>(null);
  const modalInputRef = useRef<ITextInputImperativeActions>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const { shouldShowSuggestions, resetSuggestionsVisibility } =
    useSuggestionsVisibility({
      isSuggestionsOpen,
      parentRef,
      suggestions,
      suggestionsEnabled,
      useModalSuggestions,
    });

  useEffect(() => {
    if (!useModalSuggestions) {
      return;
    }
    if (shouldShowSuggestions) {
      onModalSuggestionsOpen?.();
    } else {
      onModalSuggestionsClose?.({ closeParentContainer });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowSuggestions, useModalSuggestions]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent,
  ) => {
    e.preventDefault();
    inputRef.current?.blur();
    onSubmit?.({ type: 'submit', compId: id });
  };

  const clearButtonLabel = translate!(
    translations.NAMESPACE,
    translations.CLEAR_BUTTON_LABEL_KEY,
    translations.CLEAR_BUTTON_LABEL_DEFAULT,
  );

  const {
    activeOptionIndex,
    moveDown,
    moveUp,
    navigate,
    scrollIntoRef,
    suggestionsWithNavigation,
  } = useSuggestionNavigation(props, shouldShowSuggestions);

  const clearValue = () => {
    if (value) {
      changeValue('');
      clearSuggestions();
      onChange?.({ type: 'change', compId: id, previousValue: value });
      onClear?.({ type: 'clear', compId: id, previousValue: value });
    }
  };

  const isSuggestionsShown = Boolean(
    shouldShowSuggestions && suggestionsWithNavigation,
  );

  const isModalSuggestionsShown = isSuggestionsShown && useModalSuggestions;

  const handleEscape = () => {
    if (isSuggestionsShown && !isModalSuggestionsShown) {
      closeSuggestions();
    } else if (isModalSuggestionsShown) {
      closeSuggestions();
      clearValue();
    } else {
      clearValue();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === keyCodes.escape) {
      handleEscape();
      e.preventDefault();
    }
    if (shouldShowSuggestions) {
      const handler = (
        {
          [keyCodes.arrowUp]: moveUp,
          [keyCodes.arrowDown]: moveDown,
          [keyCodes.enter]: activeOptionIndex !== -1 ? navigate : undefined,
        } as { [key: number]: () => void }
      )[e.keyCode];

      if (handler) {
        handler();
        e.preventDefault();
      }
    }
  };

  const visibleSearchEntriesCount = suggestionsWithNavigation.items.filter(
    ({ type }) => type === 'item',
  ).length;
  const accessibilitySearchEntriesLabel =
    visibleSearchEntriesCount > 0
      ? translate!(
          translations.NAMESPACE,
          translations.SUGGESTIONS_NUM_ENTRIES_KEY,
          translations.SUGGESTIONS_NUM_ENTRIES_DEFAULT,
        ).replace(/{{amount}}/g, `${visibleSearchEntriesCount}`)
      : undefined;

  const commonFormProps = {
    useNewSuggestionsStyle,
    autocompleteValue,
    changeValue,
    clearButtonLabel,
    clearValue,
    handleKeyDown,
    handleSubmit,
    id,
    isDisabled,
    onAutocomplete,
    onChange,
    onClick,
    onDblClick,
    onKeyPress,
    placeholder,
    defaultFormAction,
    value,
    isModalSuggestionsShown,
  } as const;

  return (
    <div
      id={id}
      dir={direction}
      ref={parentRef}
      className={classNames(style.root, {
        [style.focused]: shouldShowSuggestions,
        'search-box-component-focus-ring-visible': isInputFocused,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-live="polite"
      aria-label={accessibilitySearchEntriesLabel}
      data-testid="search-box-container"
      tabIndex={-1}
    >
      {isModalSuggestionsShown && (
        <ModalSuggestions
          id={id}
          useNewSuggestionsStyle={useNewSuggestionsStyle}
          closeSuggestions={closeSuggestions}
          clearValue={clearValue}
          inputRef={modalInputRef}
          onSuggestionsFooterClick={onSuggestionsFooterClick}
          query={value}
          scrollIntoRef={scrollIntoRef}
          suggestions={suggestionsWithNavigation}
          suggestionsLoading={suggestionsLoading}
          translate={translate!}
          direction={direction}
        >
          <SearchBoxForm
            {...commonFormProps}
            dataTestId="modal-suggestions-search-box-form"
            inputRef={modalInputRef}
          />
        </ModalSuggestions>
      )}
      <Suggestions
        id={id}
        parentRef={parentRef}
        useNewSuggestionsStyle={useNewSuggestionsStyle}
        useModalSuggestions={useModalSuggestions}
        isSuggestionsShown={isSuggestionsShown && !isModalSuggestionsShown}
        onSuggestionsFooterClick={onSuggestionsFooterClick}
        query={value}
        scrollIntoRef={scrollIntoRef}
        suggestions={suggestionsWithNavigation}
        translate={translate!}
      >
        <SearchBoxForm
          {...commonFormProps}
          dataTestId="search-box-form"
          expandedDesktopSuggestions={shouldShowSuggestions}
          inputRef={inputRef}
          onBlur={(e: React.FocusEvent<Element>) => {
            onBlur?.(e);
            setIsInputFocused(false);
          }}
          onFocus={(e: React.FocusEvent<Element>) => {
            onFocus?.(e);
            setIsInputFocused(true);
            resetSuggestionsVisibility();
          }}
        />
      </Suggestions>
    </div>
  );
};

export default React.forwardRef(SearchBox);
