// TODO: Rename this component to Overlay.tsx to better reflect its responsibilities
import * as React from 'react';
import classNames from 'classnames';

import { SuggestionsProps } from './Suggestion.types';
import { SuggestionList } from './SuggestionList';
import { Footer } from './footer/Footer';

import style from './Suggestions.scss';
import { useComponentHeight } from './useComponentHeight';

export const Suggestions: React.FC<SuggestionsProps> = ({
  id,
  children,
  parentRef,
  isSuggestionsShown,
  onSuggestionsFooterClick,
  query,
  scrollIntoRef,
  suggestions,
  translate,
  useNewSuggestionsStyle,
  useModalSuggestions,
}) => {
  useComponentHeight(parentRef);

  return (
    <div
      className={classNames(style.overlay, {
        [style.suggestionsShown]: isSuggestionsShown,
      })}
    >
      <div className={style.formContainer}>{children}</div>
      {isSuggestionsShown && (
        <div className={style.suggestions} aria-hidden="true">
          <SuggestionList
            scrollIntoRef={scrollIntoRef}
            suggestions={suggestions}
            translate={translate}
            useNewSuggestionsStyle={useNewSuggestionsStyle}
          />
          {suggestions?.footer && (
            <Footer
              id={id}
              onSuggestionsFooterClick={onSuggestionsFooterClick}
              query={query}
              translate={translate}
              useNewSuggestionsStyle={useNewSuggestionsStyle}
              useModalSuggestions={useModalSuggestions}
            />
          )}
        </div>
      )}
    </div>
  );
};
