import * as React from 'react';
import classNames from 'classnames';

import { activateBySpaceOrEnterButton } from '../../../../../../core/commons/a11y';
import { ReactComponent as ClearIcon } from '../../../assets/ClearIcon.svg';
import { ReactComponent as NewClearIcon } from '../../../assets/NewClearIcon.svg';

import style from './ClearButton.scss';

interface ClearButtonProps {
  label: string;
  useNewSuggestionsStyle: boolean;
  onClick: React.MouseEventHandler;
}

export const ClearButton: React.FC<ClearButtonProps> = ({
  onClick,
  label,
  useNewSuggestionsStyle,
}) => {
  return (
    <div
      aria-label={label}
      role="button"
      tabIndex={0}
      className={classNames(style.root, {
        [style.newStyle]: useNewSuggestionsStyle,
      })}
      data-testid="clear-button"
      onClick={onClick}
      onKeyDown={activateBySpaceOrEnterButton}
    >
      {useNewSuggestionsStyle ? (
        <NewClearIcon />
      ) : (
        <ClearIcon width="8px" height="8px" />
      )}
    </div>
  );
};
