import * as React from 'react';
import classNames from 'classnames';

import { MODAL_SEARCH_SUGGESTIONS_CLOSE_BUTTON_TEST_ID } from '../../constants';
import { IModalSuggestionsProps } from '../Suggestion.types';
import { ReactComponent as ArrowRightSymbolSvg } from '../../assets/ArrowRight.svg';

import style from './CloseButton.scss';

type CloseButtonButtonProps = Pick<IModalSuggestionsProps, 'direction'> & {
  useNewSuggestionsStyle: boolean;
  onClick: React.MouseEventHandler;
};

export const CloseButton: React.FC<CloseButtonButtonProps> = ({
  direction,
  useNewSuggestionsStyle,
  onClick,
}) => {
  return (
    <div
      className={classNames(style.root, {
        [style.newStyle]: useNewSuggestionsStyle,
      })}
      data-testid={MODAL_SEARCH_SUGGESTIONS_CLOSE_BUTTON_TEST_ID}
      onClick={onClick}
    >
      <ArrowRightSymbolSvg
        className={classNames(style.button, {
          [style.flipped]: direction === 'rtl',
        })}
      />
    </div>
  );
};
